import { useTranslation } from 'react-i18next';
import Title from './Title';
import './Perf.scss';
import { ImgComparisonSlider } from '@img-comparison-slider/react';

function Perf() {
  const [t] = useTranslation();

  return (
    <div id="perf" className="perf">
      <Title title={t('perf.title1')} />

      <video
        className="perf-image"
        src="./assets/perf/perf1.mp4"
        muted
        autoPlay
        loop
        playsInline
      />

      <h1 className="perf-description">{t('perf.description1')}</h1>

      <div className="perf-sub">
        <Title className="perf-title" title={t('perf.title2')} />

        <ImgComparisonSlider className="perf-image">
          <img
            slot="first"
            src="./assets/perf/left.png"
            className="perf-image-inner"
          />
          <video
            slot="second"
            src="./assets/perf/right.mp4"
            className="perf-image-inner"
            muted
            autoPlay
            loop
            playsInline
          />
        </ImgComparisonSlider>
        <div className="perf-description">{t('perf.description2')}</div>

        <div className="perf-warning">{t('perf.warning')}</div>
      </div>
    </div>
  );
}

export default Perf;
