import './App.scss';
import About from './components/About';
import Header from './components/Header';
import Home from './components/Home';
import Purchase from './components/Purchase';
import Footer from './components/Footer';
import Contact from './components/Contact';
import Perf from './components/Perf';
import PurchasePopup from './components/PurchasePopup';
import Software from './components/Software';

function App() {
  return (
    <div className="App">
      <Contact />
      <PurchasePopup />

      <Header />
      <Home />
      <About />
      <Perf />
      <Purchase />
      <Software />

      <Footer />
    </div>
  );
}

export default App;
