import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import './Software.scss';

function Software() {
  const [t] = useTranslation();

  return (
    <div id="software" className="software">
      <div className="software-container">
        <motion.div
          className="software-title"
          initial={{ transform: 'translate(0, 50%)', opacity: 0 }}
          transition={{
            ease: 'easeInOut',
            duration: 0.7,
            delay: 0.5,
          }}
          viewport={{ once: true }}
          whileInView={{ transform: 'translate(0, 0)', opacity: 1 }}
        >
          <img src="./assets/logo_only.png" className="software-title-logo" />
          <div className="software-title-text">Ossia</div>
        </motion.div>
        <div className="software-description">{t('software.description')}</div>
        <motion.button
          className="software-button btn"
          onClick={() => document.getElementById('buy-modal').showModal()}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          disabled
        >
          <div>{t('software.button')}</div>
        </motion.button>
      </div>
      <img
        className="software-image"
        src="./assets/software/software.png"
        alt="software"
      />
    </div>
  );
}

export default Software;
