import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import './About.scss';
import settings from '../settings.json';
import Title from './Title';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

function About() {
  const [t] = useTranslation();
  const [cur, setCur] = useState(-1);

  const move = (i) => {
    const carousel = document.getElementById('carousel');
    setCur(i);
    const card = document.getElementById(`card${i + 1}`);
    card.currentTime = 0;
    card.play();
    carousel.scrollTo({
      left: card.clientWidth * i,
      behavior: 'smooth',
    });
  };

  const arr = [7.552, 12.096, 14.866666];

  useEffect(() => {
    move(0);
  }, []);

  return (
    <div id="about" className="about">
      <Title title={t('about.title')} />

      <div
        id="carousel"
        className="carousel carousel-center space-x-4 p-4 about-carousel"
      >
        {settings.videos.map(({ id, src, up, down }, i) => (
          <div className="carousel-item" key={id}>
            <div className="video-text video-text-up">{t(up)}</div>
            <video
              id={id}
              className="rounded-box video"
              src={src}
              muted
              playsInline
              onEnded={() => {
                if (cur === i) {
                  move((i + 1) % settings.videos.length);
                }
              }}
            />
            <div className="video-text video-text-down">{t(down)}</div>
          </div>
        ))}
      </div>

      <div id="buttons" className="about-buttons">
        {settings.videos.map(({ button }, i) => (
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            id={button}
            key={button}
            className={classNames('button', {
              active: cur === i,
              inactive: cur !== i,
            })}
            onClick={() => move(i)}
          >
            {cur === i ? (
              <CountdownCircleTimer
                isPlaying
                className="timer"
                colors="white"
                trailColor="grey"
                size={30}
                strokeWidth={3}
                duration={arr[i]}
                key={`timer${i}`}
              />
            ) : (
              <div />
            )}
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default About;
