import { motion } from 'framer-motion';
import './Title.scss';

function Title({ title, left }) {
  return (
    <motion.div
      className="title"
      initial={{ transform: 'translate(0, 50%)', opacity: 0 }}
      transition={{
        ease: 'easeInOut',
        duration: 0.7,
        delay: 0.5,
      }}
      viewport={{ once: true }}
      whileInView={{ transform: 'translate(0, 0)', opacity: 1 }}
      style={{
        textAlign: left ? 'start' : 'center',
      }}
    >
      {title}
    </motion.div>
  );
}

export default Title;
